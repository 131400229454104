import '../index.css';
import React from 'react';
import Card from './Card';
import Header from './Header';
import { CurrentUserContext } from '../contexts/CurrentUserContext';
import { AuthContext } from '../contexts/AuthContext';

function Main({ onEditProfile, onAddPlace, onEditAvatar, handleCardClick, onCardLike, onCardDelete, cards, onLogout }) {

    const { currentUser } = React.useContext(CurrentUserContext);
    const { setAuth } = React.useContext(AuthContext);

    function logout() {
        // document.cookie = "token=; expires = Thu, 01 Jan 1970 00:00:00 GMT";
        document.cookie = 'token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        onLogout();
        setAuth(null);
    }

    return (
        <>
            <Header buttonText={'Выйти'} buttonAction={logout} buttonPath={'/sign-in'}/>
            <main className="page">
                <section className="profile">
                    <div className="profile__avatar-container">
                        <div className="profile__avatar" style={{ backgroundImage: `url(${currentUser.avatar})`, }}></div>
                        <div className="profile__avatar-overlay" onClick={onEditAvatar}></div>
                    </div>
                    <div className="profile__info">
                        <div className="profile__info-name-container">
                            <h1 className="profile__info-name">{currentUser.name}</h1>
                            <button className="profile__info-editbutton" type="button" aria-label="Редактировать профиль" onClick={onEditProfile}></button>
                        </div>
                        <p className="profile__info-titles">{currentUser.about}</p>
                    </div>
                    <button className="profile__info-addbutton" type="button" aria-label="Добавить карточку" onClick={onAddPlace}></button>
                </section>
                <section className="cards">
                    {cards.map(card => (
                        <Card cardData={card} key={card._id} handleCardClick={handleCardClick} onCardLike={onCardLike} onCardDelete={onCardDelete}></Card>
                    ))}
                </section>
            </main>
        </>
    )
}

export default Main;
function ImagePopup({ selectedCard, isOpen, onClose }) {
    const visible = `${isOpen && 'popup_visible'}`;

    return (
        <div className={`popup popup_type_image ${visible}`}>
            <div className="popup__pic-container">
                <button className="popup__close" onClick={onClose} type="button" aria-label="Выход"></button>
                <img src={selectedCard.link} alt={selectedCard.name} className="popup__pic-img"></img>
                <p className="popup__pic-title">{selectedCard.name}</p>
            </div>
        </div>
    )
}

export default ImagePopup;
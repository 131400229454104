import { CurrentUserContext } from '../contexts/CurrentUserContext';
import React from 'react';

function Card({ cardData, handleCardClick, onCardLike, onCardDelete }) {
    function onCardClick() {
        handleCardClick(cardData);
    }

    const { currentUser } = React.useContext(CurrentUserContext);

    const isOwn = cardData.owner === currentUser._id;

    const cardDeleteButtonClassName = (
        `card__trash ${!isOwn && 'card__trash_hidden'}`
    );

    const isLiked = cardData.likes.some(i => i === currentUser._id);

    const cardLikeButtonClassName = `card__likebtn ${isLiked && 'cards__card-likebtn_activated'}`;

    return (
        <article className="card">
            <div className="card__img-container">
                <img src={cardData.link} alt={cardData.name} onClick={onCardClick} className="card__img"></img>
                <button className={cardDeleteButtonClassName} aria-label="Удалить" type="button" onClick={() => onCardDelete(cardData)}></button>
            </div>
            <h2 className="card__title">{cardData.name}</h2>
            <div className="card__like-container">
                <button className={cardLikeButtonClassName} aria-label="Лайк" type="button" onClick={() => onCardLike(cardData)}></button>
                <p className="card__like-counter">{cardData.likes.length}</p>
            </div>
        </article>
    );
}

export default Card;
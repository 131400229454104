import '../index.css';
import React from "react";
import Header from './Header';
// import { useHistory } from "react-router-dom";
// import { AuthContext } from '../contexts/AuthContext';

function Login({ onSignIn }) {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    // const history = useHistory();
    // const { setAuth } = React.useContext(AuthContext);

    function handleChangeEmail(e) {
        setEmail(e.target.value);
    }

    function handleChangePassword(e) {
        setPassword(e.target.value);
    }

    function handleSubmit(e) {
        e.preventDefault();
        onSignIn(email, password);
    }

    return (
        <>
            <Header buttonText={'Зарегистрироваться'} buttonPath={'/sign-up'} />
            <div className='auth'>
                <h2 className='auth__title'>Вход</h2>
                <form className='auth__form' onSubmit={handleSubmit}>
                    <label className='auth__label'>
                        <input value={email || ''} onChange={handleChangeEmail} className='auth__input' type="email" name="link" id="avatar-link" placeholder="Email"></input>
                        {/* <span className='auth__error' id="avatar-link-error">Текст ошибки</span> */}
                    </label>
                    <label className='auth__label'>
                        <input value={password || ''} onChange={handleChangePassword} className='auth__input' type="text" name="link" id="avatar-link" placeholder="Пароль"></input>
                    </label>
                    <button type="submit" className="auth__submit" aria-label="Сохранить">Войти</button>
                </form>
            </div>
        </>
    );
}

export default Login;
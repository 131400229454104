import PopupWithForm from "./PopupWithForm"
import React from "react";

function AddPlacePopup({ isOpen, onClose, onAddPlace, isLoading }) {

    function handleSubmit(e) {
        e.preventDefault();
        onAddPlace({name: name, link: link});
    }

    React.useEffect(() => {
        setName('');
        setLink('');
    }, [isOpen]);

    const [name, setName] = React.useState('');
    const [link, setLink] = React.useState('');

    function handleChangeName(e) {
        setName(e.target.value);
    }

    function handleChangeLink(e) {
        setLink(e.target.value);
    }

    return (
        <PopupWithForm name="card" title="Новое место"
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={handleSubmit}
            buttonText={isLoading? 'Сохранение...' : 'Сохранить'}>
            <label className="popup__form-label">
                <input type="text" value={name} onChange={handleChangeName} name="name" id="place" required={true} placeholder="Название места" className="popup__form-input popup__form-input_name_place" minLength={2} maxLength={30}></input>
                <span className="popup__form-error" id="place-error">Текст ошибки</span>
            </label>
            <label className="popup__form-label">
                <input type="url" value={link} onChange={handleChangeLink} name="link" id="link" required={true} placeholder="Ссылка на изображение" className="popup__form-input popup__form-input_name_link"></input>
                <span className="popup__form-error" id="link-error">Текст ошибки</span>
            </label>
        </PopupWithForm>
    );
}

export default AddPlacePopup;
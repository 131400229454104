import '../index.css';

function InfoTooltip({ isOpen, message, status, onClose }) {
    const visible = `${isOpen && 'popup_visible'}`;
    const statusClass = status ? 'popup__icon_type_success' : 'popup__icon_type_failure';

    return (
        <div className={`popup popup_type_infotooltip ${visible}`}>
            <div className="popup__container">
                <button className='popup__close' type="button" aria-label="Выход" onClick={onClose}></button>
                <div className={`popup__icon ${statusClass}`}></div>
                <p className="popup__message">{message}</p>
            </div>
        </div>
    );
}

export default InfoTooltip;
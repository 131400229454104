import '../index.css';
import React from 'react';
import Main from './Main';
import Footer from './Footer';
import PopupWithForm from './PopupWithForm';
import EditProfilePopup from './EditProfilePopup';
import EditAvatarPopup from './EditAvatarPopup';
import AddPlacePopup from './AddPlacePopup';
import ImagePopup from './ImagePopup';
import { CurrentUserContext } from '../contexts/CurrentUserContext';
import { api } from '../utils/Api';

function Mesto() {
    const [isEditProfilePopupOpen, setEditProfilePopupOpened] = React.useState(false);
    const [isEditAvatarPopupOpen, setEditAvatarPopupOpened] = React.useState(false);
    const [isAddPlacePopupOpen, setAddPlacePopupOpened] = React.useState(false);
    const [isImagePopupOpen, setImagePopupOpened] = React.useState(false);

    const [isLoading, setIsLoading] = React.useState(false);

    const [cards, setCards] = React.useState([]);

    const [selectedCard, setSelectedCard] = React.useState({ link: '', name: '', _id: '' });

    const { currentUser, setCurrentUser } = React.useContext(CurrentUserContext);

    const isOpen = isEditAvatarPopupOpen || isEditProfilePopupOpen || isAddPlacePopupOpen || selectedCard.link

    React.useEffect(() => {
        function closeByEscape(evt) {
            if (evt.key === "Escape") {
                closeAllPopups();
            }
        }
        function handleOverlay(evt) {
            if (evt.target.classList.contains("popup_visible")) {
                closeAllPopups();
            }
        }
        if (isOpen) {
            document.addEventListener("keydown", closeByEscape);
            document.addEventListener("mousedown", handleOverlay);

            return () => {
                document.removeEventListener("keydown", closeByEscape);
                document.removeEventListener("mousedown", handleOverlay);
            };
        }
    }, [isOpen]);

    React.useEffect(() => {
        api.getInitialCards()
            .then(res => {
                setCards(res);
            })
            .catch(api.catchError);
    }, []);

    function handleCardLike(card) {
        const isLiked = card.likes.some(i => i === currentUser._id);
        api.changeLikeCardStatus(card._id, isLiked)
            .then((newLikes) => {
                setCards((state) => state.map((c) => {
                    if(c._id === card._id) {
                        c.likes = newLikes;
                    }
                    return c;
                }));
            })
            .catch(api.catchError);
    }

    function handleCardDelete(card) {
        api.deleteCard(card._id)
            .then(() => {
                setCards((state) => state.filter(item => item._id !== card._id))
            })
            .catch(api.catchError);
    }

    function handleEditAvatarClick() {
        setEditAvatarPopupOpened(true);
    }

    function handleEditProfileClick() {
        setEditProfilePopupOpened(true);
    }

    function handleAddPlaceClick() {
        setAddPlacePopupOpened(true);
    }

    function handleCardClick(card) {
        setSelectedCard(card);
        setImagePopupOpened(true);
    }

    function closeAllPopups() {
        setAddPlacePopupOpened(false);
        setEditProfilePopupOpened(false);
        setEditAvatarPopupOpened(false);
        setImagePopupOpened(false);
        setSelectedCard({ link: '', name: '' });
    }

    function handleUpdateUser(user) {
        setIsLoading(true);
        api.patchUserInfo(user)
            .then(res => {
                setCurrentUser(res);
                closeAllPopups();
            })
            .catch(api.catchError)
            .finally(() => setIsLoading(false));
    }

    function handleAddPlace(card) {
        setIsLoading(true);
        api.postNewCard(card)
            .then(res => {
                setCards([res, ...cards]);
                closeAllPopups();
            })
            .catch(api.catchError)
            .finally(() => setIsLoading(false));
    }

    function handleUpdateAvatar(user) {
        setIsLoading(true);
        api.patchAvatar(user.avatar)
            .then(res => {
                setCurrentUser(res);
                closeAllPopups();
            })
            .catch(api.catchError)
            .finally(() => setIsLoading(false));
    }

    function onLogout() {
        api.logout();
    }

    return (
        <>
            <Main
                onEditProfile={handleEditProfileClick}
                onAddPlace={handleAddPlaceClick}
                onEditAvatar={handleEditAvatarClick}
                handleCardClick={handleCardClick}
                cards={cards}
                onLogout={onLogout}
                onCardLike={handleCardLike}
                onCardDelete={handleCardDelete} />
            <EditProfilePopup isOpen={isEditProfilePopupOpen} onClose={closeAllPopups} onUpdateUser={handleUpdateUser} isLoading={isLoading} />
            <AddPlacePopup isOpen={isAddPlacePopupOpen} onClose={closeAllPopups} onAddPlace={handleAddPlace} isLoading={isLoading} />
            <EditAvatarPopup isOpen={isEditAvatarPopupOpen} onClose={closeAllPopups} onUpdateAvatar={handleUpdateAvatar} isLoading={isLoading} />
            <PopupWithForm name="are-you-sure" title="Вы уверены?" onClose={closeAllPopups} buttonText={'Да'} />
            <ImagePopup selectedCard={selectedCard} isOpen={isImagePopupOpen} onClose={closeAllPopups}>
            </ImagePopup>
            <Footer />
        </>
    );
}

export default Mesto;
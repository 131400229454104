import '../index.css';
import React from 'react';
import Mesto from './Mesto';
import { Route, Switch, useHistory } from "react-router-dom";
import { CurrentUserContext } from '../contexts/CurrentUserContext';
import { AuthContext } from '../contexts/AuthContext';
import ProtectedRoute from './ProtectedRoute';
import Register from './Register';
import Login from './Login';
import InfoTooltip from './InfoTooltip';
import { authorization } from '../utils/Auth';

function App() {
    const [currentUser, setCurrentUser] = React.useState({ name: '', about: '', avatar: '', _id: '' });
    const history = useHistory();
    const [auth, setAuth] = React.useState(null);

    const [isInfoTooltipPopupOpened, setInfoTooltipPopupOpened] = React.useState(false);
    const [infoTooltipMessage, setInfoTooltipMessage] = React.useState('');
    const [infoTooltipStatus, setInfoTooltipStatus] = React.useState(false);

    const loggedIn = auth !== null;

    function onSignIn(email, password) {
        authorization.signIn({ email, password })
            .then((res) => {
                setAuth({ email });
            })
            .then(() => {
                authorization.checkToken()
                    .then((data) => {
                        setAuth({ email: data.email });
                        setCurrentUser({ name: data.name, about: data.about, avatar: data.avatar, _id: data._id });
                        history.push('/');
                    })
            })
            .catch(authorization.catchError);
    }

    React.useEffect(() => {
        authorization.checkToken()
            .then((data) => {
                setAuth({ email: data.email });
                setCurrentUser({ name: data.name, about: data.about, avatar: data.avatar, _id: data._id });
                history.push('/');
            })
            .catch(authorization.catchError);
    }, []);

    function onInfoTooltipClose() {
        setInfoTooltipPopupOpened(false);
        history.push('/sign-in');
    }

    function onSignUp(email, password) {
        return authorization.signUp({ email, password })
            .then(() => {
                setInfoTooltipMessage('Вы успешно зарегистрировались!');
                setInfoTooltipStatus(true);
            })
            .catch(() => {
                setInfoTooltipMessage('Что-то пошло не так! Попробуйте ещё раз.');
                setInfoTooltipStatus(false);
            })
            .finally(() => {
                setInfoTooltipPopupOpened(true);
            });
    }

    return (
        <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }} >
            <AuthContext.Provider value={{ auth, setAuth }} >
                <InfoTooltip isOpen={isInfoTooltipPopupOpened} message={infoTooltipMessage} status={infoTooltipStatus} onClose={onInfoTooltipClose} />
                <Switch>
                    <Route path="/sign-up">
                        <Register onSignUp={onSignUp} />
                    </Route>
                    <Route path="/sign-in">
                        <Login onSignIn={onSignIn} />
                    </Route>
                    <ProtectedRoute component={Mesto} loggedIn={loggedIn} path="/" />
                </Switch>
            </AuthContext.Provider>
        </CurrentUserContext.Provider>
    );
}

export default App;
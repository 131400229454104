const validationSettings = {
    formSelector: '.popup__form',
    inputSelector: '.popup__form-input',
    submitButtonSelector: '.popup__form-save',
    inactiveButtonClass: 'popup__form-save_disabled',
    inputErrorClass: 'popup__form-input_type_error',
    errorClass: 'popup__form-error_visible'
};

const cardTemplateSelector = '#card-template';

const accesOptions = {
    baseUrl: 'https://api.paulogorbunov.nomoredomains.club',
    // baseUrl: window.location.protocol+'//localhost:3000',
    reqOptions: {
        credentials: 'include',
        mode: 'cors',
    },
    authHeaders: {
        "Content-Type": "application/json",
    }
}

export { validationSettings, cardTemplateSelector, accesOptions };
import '../index.css';
import React from 'react';
import { Link } from "react-router-dom";
import { AuthContext } from '../contexts/AuthContext';

function Header({ buttonText, buttonAction, buttonPath }) {
    const { auth } = React.useContext(AuthContext);

    return (
        <header className="header">
            <Link to='/' className="header__logo"></Link>
            {auth && <p className="header__email">{auth.email}</p>}
            <Link to={buttonPath} className="header__enter" onClick={buttonAction}>{buttonText}</Link>
        </header>
    )
}

export default Header;
import PopupWithForm from "./PopupWithForm"
import React from "react";

function EditAvatarPopup({ isOpen, onClose, onUpdateAvatar, isLoading }) {
    const inputRef = React.useRef();

    React.useEffect(() => {
        inputRef.current.value = '';
    }, [isOpen]);

    function handleSubmit(e) {
        e.preventDefault();

        onUpdateAvatar({
            avatar: inputRef.current.value,
        });
    }

    return (
        <PopupWithForm name="avatar" title="Обновить аватар"
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={handleSubmit}
            buttonText={isLoading? 'Сохранение...' : 'Сохранить'}>
            <label className="popup__form-label">
                <input ref={inputRef} type="url" name="link" id="avatar-link" required={true} placeholder="Ссылка на изображение" defaultValue="" className="popup__form-input popup__form-input_name_link"></input>
                <span className="popup__form-error" id="avatar-link-error">Текст ошибки</span>
            </label>
        </PopupWithForm>
    );
}

export default EditAvatarPopup;
import PopupWithForm from "./PopupWithForm"
import { CurrentUserContext } from "../contexts/CurrentUserContext";
import React from "react";

function EditProfilePopup({ isOpen, onClose, onUpdateUser, isLoading }) {

    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');

    const {currentUser} = React.useContext(CurrentUserContext);

    React.useEffect(() => {
        setName(currentUser.name);
        setDescription(currentUser.about);
    }, [currentUser, isOpen]);

    function handleChangeName(e) {
        setName(e.target.value);
    }

    function handleChangeDescription(e) {
        setDescription(e.target.value);
    }

    function handleSubmit(e) {
        e.preventDefault();
      
        onUpdateUser({
          name,
          about: description,
        });
      } 

    return (
        <PopupWithForm name="profile" title="Редактировать профиль"
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={handleSubmit}
            buttonText={isLoading? 'Сохранение...' : 'Сохранить'}>
            <label className="popup__form-label">
                <input type="text" value={name || ''} onChange={handleChangeName} name="name" id="name" required={true} className="popup__form-input popup__form-input_name_name" minLength={2} maxLength={40}></input>
                <span className="popup__form-error" id="name-error">Текст ошибки</span>
            </label>
            <label className="popup__form-label">
                <input type="text" value={description || ''} onChange={handleChangeDescription} name="about" id="occupation" required={true} className="popup__form-input popup__form-input_name_occupation" minLength={2} maxLength={200}></input>
                <span className="popup__form-error" id="occupation-error">Текст ошибки</span>
            </label>
        </PopupWithForm>
    )
}

export default EditProfilePopup;
function PopupWithForm({ name, title, isOpen, onClose, onSubmit, buttonText, children }) {

    const visible = `${isOpen && 'popup_visible'}`;

    return (
        <div className={`popup popup_type_${name} ${visible}`}>
            <div className="popup__container">
                <h3 className="popup__form-title">{title}</h3>
                <button className="popup__close" type="button" aria-label="Выход" onClick={onClose}></button>
                <form action="none" name={name} className="popup__form" onSubmit={onSubmit}>
                    {children}
                    <button type="submit" className="popup__form-save" aria-label="Сохранить">{buttonText}</button>
                </form>
            </div>
        </div>
    )
}

export default PopupWithForm;
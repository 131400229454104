import '../index.css';
import React from "react";
import Header from './Header';
import { Link } from "react-router-dom";

function Register({ onSignUp }) {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');

    function handleChangeEmail(e) {
        setEmail(e.target.value);
    }

    function handleChangePassword(e) {
        setPassword(e.target.value);
    }

    function handleSubmit(e) {
        e.preventDefault();

        onSignUp(email, password);
    }

    return (
        <>
            <Header buttonText={'Войти'} buttonPath={'/sign-in'} />
            <div className='auth'>
                <h2 className='auth__title'>Регистрация</h2>
                <form className='auth__form' onSubmit={handleSubmit}>
                    <label className='auth__label'>
                        <input value={email || ''} onChange={handleChangeEmail} className='auth__input' type="email" name="link" id="avatar-link" placeholder="Email"></input>
                        {/* <span className='auth__error' id="avatar-link-error">Текст ошибки</span> */}
                    </label>
                    <label className='auth__label'>
                        <input value={password || ''} onChange={handleChangePassword} className='auth__input' type="text" name="link" id="avatar-link" placeholder="Пароль"></input>
                    </label>
                    <button type="submit" className="auth__submit" aria-label="Сохранить">Зарегистрироваться</button>
                    <p className='auth__footer'>Уже зарегистрированы? <Link to={'/sign-in'} className="auth__enter">Войти</Link></p>
                </form>
            </div>
        </>
    );
}

export default Register;
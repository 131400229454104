import { accesOptions } from './constants';

class Api {
    constructor(options) {
        this._baseUrl = options.baseUrl;
        this._reqOptions = options.reqOptions;
        this._headers = {
            'Content-Type': 'application/json',
        };
    }

    _checkResponse(res) {
        if (res.ok) {
            return res.json();
        } else {
            return Promise.reject(`Ошибка ${res.status}`);
        }
    }

    getInitialCards() {
        return fetch(this._baseUrl + '/cards/', {
            headers: this._headers,
            ...this._reqOptions,
        }).then(this._checkResponse);
    }

    getUserInfo() {
        return fetch(this._baseUrl + '/users/me/', {
            headers: this._headers,
            ...this._reqOptions,
        }).then(this._checkResponse);
    }

    patchUserInfo(data) {
        return fetch(this._baseUrl + '/users/me/', {
            method: 'PATCH',
            headers: this._headers,
            ...this._reqOptions,
            body: JSON.stringify({
                name: data.name,
                about: data.about
            })
        }).then(this._checkResponse);
    }

    postNewCard(data) {
        return fetch(this._baseUrl + '/cards/', {
            method: 'POST',
            headers: this._headers,
            ...this._reqOptions,
            body: JSON.stringify({
                name: data.name,
                link: data.link
            })
        }).then(this._checkResponse);
    }

    patchAvatar(link) {
        return fetch(this._baseUrl + '/users/me/avatar/', {
            method: 'PATCH',
            headers: this._headers,
            ...this._reqOptions,
            body: JSON.stringify({ avatar: link })
        }).then(this._checkResponse);
    }

    deleteCard(id) {
        return fetch(this._baseUrl + '/cards/' + id, {
            method: 'DELETE',
            headers: this._headers,
            ...this._reqOptions,
        }).then(this._checkResponse);
    }

    putLike(id) {
        return fetch(this._baseUrl + '/cards/' + id + '/likes/', {
            method: 'PUT',
            headers: this._headers,
            ...this._reqOptions,
        })
            .then(this._checkResponse);
    }

    deleteLike(id) {
        return fetch(this._baseUrl + '/cards/' + id + '/likes/', {
            method: 'DELETE',
            headers: this._headers,
            ...this._reqOptions,
        })
            .then(this._checkResponse);
    }

    changeLikeCardStatus(id, isLiked) {
        if (isLiked) {
            return this.deleteLike(id);
        } else {
            return this.putLike(id);
        }
    }

    logout() {
        return fetch(this._baseUrl + '/logout/', {
            method: 'POST',
            headers: this._headers,
            ...this._reqOptions,
        })
        .then(this._checkResponse);
    }

    catchError(err) {
        console.log(err);
    }
}

const api = new Api(accesOptions);

export { api };